<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png'); background-color: #597dfe !important;"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-12">
        <img alt="Logo" src="media/logos/logo.png" class="h-45px" />
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div
        class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto text-center"
      >
        <h1
          :class="{
            'text-success': hasSuccessed,
            'text-warning': hasFailed,
            'text-danger': hasWarned
          }"
        >
          {{ translate("validationTitle") }}
        </h1>

        <h4
          class="mt-5"
          :class="{
            'text-success': true
          }"
        >
          {{ translate("validationDetails") }}
        </h4>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-activation",

  setup() {
    const { t, te } = useI18n();

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  },

  data() {
    return {
      isWorking: false,
      hasSuccessed: false,
      hasFailed: false,
      hasWarned: false,
      validationResult: {
        type: "",
        message: "...",
        details: "..."
      }
    };
  },
  mounted() {
    this.verifyToken();
  },
  methods: {
    // Form submit function
    verifyToken() {
      const token = this.$route.query;
      // Stop waiting
      this.isWorking = true;
      setTimeout(() => {
        if (!token || typeof token === undefined) {
          // Error
          this.validationResult = {
            type: "error",
            message: this.translate("validationErrorTitle"),
            details: this.translate("validationErrorDetails")
          };
          this.isWorking = false;
          return;
        }

        return this.$store
          .dispatch(Actions.ACTIVATE_ACCOUNT, token)
          .then(() => {
            this.$router.push("/dashboard");
          });
      }, 1533);
    }
  }
});
</script>
